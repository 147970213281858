<script setup>
import { useStrong } from "@/composables/useStrong";
import { useApp } from "~/composables/useApp";

const { bibles, selections } = useApp();
const { fetchDefinition } = useStrong();

const dialog = ref(false);
const strongModel = ref("");

useListen("strong:open", handleOpen);

function handleOpen() {
  dialog.value = true;
}

function close() {
  dialog.value = false;
}

function addListeners() {
  const strongsNodesColl = document.querySelectorAll("S");
  const strongsElemColl = Array.from(strongsNodesColl);

  strongsElemColl.map((el) => {
    el.addEventListener("click", handleStrongClick, { passive: true });
  });
}

function watchStrongs() {
  watch(
    () => bibles.singleBible.location,
    () => setTimeout(() => addListeners(bibles.singleBible.location)),
    {
      deep: true,
      immediate: true,
    }
  );
}

async function handleStrongClick(e) {
  // disable strong numbers click if verses selection mode enabled
  if (selections.singleBible.isEnabled.value) return;

  e.stopPropagation();

  const bookPlace = bibles.singleBible.location.bookModel.bookPlace;
  const strongLocale = bibles.singleBible.location.bibleModel.bibleLocale;
  const strongPrefix = bookPlace == "ot" ? "H" : "G";
  const strongNumber = e.target.innerText;
  const strongCode = strongPrefix + strongNumber;

  strongModel.value = await fetchDefinition({ strongCode, strongLocale });

  // strongCode.value = strongCode;

  dialog.value = true;
}

onMounted(() => {
  watchStrongs();
});
</script>
<template>
  <v-dialog
    v-model="dialog"
    bottom-sheet
    class="strong-dialog"
    :options="{ maxWidth: 'var(--screen-max-width)' }"
  >
    <v-navigation-header
      @goback="close()"
      label="Strong's Dictionary"
    ></v-navigation-header>
    <div class="strong-content">
      <div class="strong-code">{{ strongModel.strongCode }}</div>
      <div class="strong-def mt-5" v-html="strongModel.strongDef"></div>
      <div class="strong-def mt-5">
        <span class="def-title">{{ transl("Pronunciation") }}:</span>
        <span class="def-value">{{ strongModel.strongPronun }}</span>
      </div>
      <div class="strong-def">
        <span class="def-title">{{ transl("Transliteration") }}:</span>
        <span class="def-value">{{ strongModel.strongTranslit }}</span>
      </div>
    </div>
  </v-dialog>
</template>
<style scoped>
.strong-dialog :deep() [bottom-sheet] .dialog {
  border-radius: 20px 20px 0 0;
  /* max-width: 300px !important; */
  width: 50px;
}
.strong-content {
  overflow-y: auto;
  height: 400px;
  background-color: white;
  padding: 20px 20px;
  line-height: 30px;
}
.strong-code {
  font-size: 30px;
}

.strong-def {
  font-size: 22px;
}
.def-title {
  /* font-size: 22px; */
  /* font-style: italic; */
  /* color: rgb(109, 109, 109); */
  font-weight: 700;

  margin-right: 10px;
}

.def-value {
  /* font-size: 22px; */
  /* font-weight: 700; */
}
</style>
